import "babel-polyfill";
import Scrollbar from 'smooth-scrollbar';
import helpers from './helpers/helpers';
import header from './components/header';
import products from './components/products';
import overlay from './components/overlay';
import projectCarousel from './components/project-carousel';
import work from './components/work';
import contact from './components/contact';

let site_header = $('.website-header');
let scrollbar = Scrollbar.init( $('.main-scrollbar')[0], {
	synCallbacks: true
});

scrollbar.addListener(function(context) {
	if( context.offset.y > 0 ) {
		site_header.addClass('bg');
	}
	else {
		site_header.removeClass('bg');
	}
});

helpers.triggerAnimationsInView(15);
header.init();
overlay.init();
projectCarousel.init();
products.init();
work.init();
contact.init();


//
// Helps with debugging PHP on the front end
// instead of prin_r or var_dump use code($array)
// -----------------------------------------------------------------------------

var isDeclared = true;

try{ hljs; }
catch(e) {
    if(e.name == "ReferenceError") {
        isDeclared = false;
    }
}

if(isDeclared) {
 hljs.initHighlightingOnLoad();
}
