import inView from 'in-view';

const Helpers = {

  outerWidth: function(el) {
    let width = el.offsetWidth;
    let style = getComputedStyle(el);

    width += parseInt(style.marginLeft) + parseInt(style.marginRight);
    return width;
  },

  outerHeight: function(el) {
    let height = el.offsetHeight;
    let style = getComputedStyle(el);

    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
  },

  whichAnimationEvent: function() {
    let anim;
    let element =  document.createElement('fakeelement');
    let animations = {
      'animation'       : 'animationend',
      'OAnimation'      : 'oAnimationEnd',
      'MozAnimation'    : 'animationend',
      'WebkitAnimation' : 'webkitAnimationEnd'
    };

    for (anim in animations) {
      if (element.style[anim] !== undefined) {
        return animations[anim];
      }
    }
  },

  whichTransitionEvent: function() {

    let trans;
    let element = document.createElement('fakeelement');
    let transitions = {
      'transition'       : 'transitionend',
      'OTransition'      : 'oTransitionEnd',
      'MozTransition'    : 'transitionend',
      'WebkitTransition' : 'webkitTransitionEnd'
    };

    for (trans in transitions) {
      if (element.style[trans] !== undefined) {
        return transitions[trans];
      }
    }
  },

  debounce: function debounce(func, wait, immediate) {
  	var timeout;
  	return function() {
  		var context = this, args = arguments;
  		var later = function() {
  			timeout = null;
  			if (!immediate) func.apply(context, args);
  		};
  		var callNow = immediate && !timeout;
  		clearTimeout(timeout);
  		timeout = setTimeout(later, wait);
  		if (callNow) func.apply(context, args);
  	};
  },

  lockBody: function(pBoolScrollbar, pNumScrollbarWidth) {

    // enable overflow-y: hidden on <html> and <body>
    elHTML.setAttribute('data-scrollable', 'locked');

    // if necessary, accomodate for scrollbar width
    if (pBoolScrollbar) {
      elBody.style.paddingRight = (pNumScrollbarWidth / 10) + 'rem';
      // elHeader.style.paddingRight = (pNumScrollbarWidth / 10) + 'rem';
    }

  },

  unlockBody: function(pBoolScrollbar) {

    // disable overflow-y: hidden on <html> and <body>
    elHTML.setAttribute('data-scrollable', 'unlocked');

    // if necessary, remove scrollbar width styles
    // should be expanded to restore original padding if needed
    if (pBoolScrollbar) {
      elBody.style.paddingRight = '0'; // pNumOriginalPadding
      // elHeader.style.paddingRight = '0'; // pNumOriginalPadding
    }

  },

  getRandomInt: function(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },

  triggerAnimationsInView: function(offset) {

    if ("MutationObserver" in window) {

      if(!offset) offset = 0;

      inView.threshold(offset);

      inView('.trigger-animation')
      .on('enter', function(context) {
        $(context).addClass('fire');
      })
      .on('exit', function(context) {
      });

    }

    else {
      $('.trigger-animation').addClass('fire');
    }

  }

}

export default Helpers;
