import helpers from '../helpers/helpers';

const body = $('body');
const overlay = $('.overlay');

const Overlay = {

  init: function() {

    let self = this;
    let trigger = $('.trigger-overlay');
    let close = $('.close-overlay');
    let transEnd = helpers.whichTransitionEvent();

    trigger.on('click', function(event) {
      event.preventDefault();

      if(!body.hasClass('building-overlay')) {
        body.addClass('building-overlay');
        $(this).addClass('active');
        let id = $(this).attr('id').split('-')[1];
        self.get_project_data(id);
      }

    });

    close.on('click', function(event) {

      overlay.one(transEnd, function(event) {
        overlay.find('.overlay-content').empty();
      });

      body.removeClass('overlay-showing');

    });

  },

  get_project_data: function(id) {

    let self = this;

    $.ajax({
      url: AjaxHandler.ajaxurl,
      type: 'GET',
      dataType: 'JSON',
      data: {
        action: 'project_data',
        id: id
      },
    })
    .done(function(response) {
      self.build_overlay(response);
      console.log("success");
    })
    .fail(function() {
      console.log("error");
    })
    .always(function() {
      console.log("complete");
    });


  },

  build_overlay: function(response) {

    let images = response.images;
    let title = response.title;
    let img_list = '';

    img_list += `<h2 class="carousel-title">${title}</h2>`;

    img_list += '<div class="carousel">';

      for(let i = 0; i < images.length; i++) {
        let src = images[i]['sizes']['large'];
        img_list += `<div><img src="${src}" alt="project image" /></div>`;
      }

    img_list += '</div>';

    overlay.find('.overlay-content').html(img_list);

    overlay.on('init', function(){
      body.removeClass('building-overlay').addClass('overlay-showing');
    });

    overlay.find('.carousel').slick({
      slidesToShow: 3,
      centerMode: true,
      focusOnSelect: true,
      arrows:false,
      dots:true,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false
          }
        }
      ]
    });

  }


}

export default Overlay;
