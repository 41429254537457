const Products = {

  parallax: function() {
    $('.scroll-content').stellar({
     // Set scrolling to be in either one or both directions
     horizontalScrolling: false,
     verticalScrolling: true,

     // Set the global alignment offsets
     horizontalOffset: 0,
     verticalOffset: 0,

     // Refreshes parallax content on window load and resize
     responsive: true,

     // Select which property is used to calculate scroll.
     // Choose 'scroll', 'position', 'margin' or 'transform',
     // or write your own 'scrollProperty' plugin.
     scrollProperty: 'transform',

     // Select which property is used to position elements.
     // Choose between 'position' or 'transform',
     // or write your own 'positionProperty' plugin.
     positionProperty: 'transform',

     // Enable or disable the two types of parallax
     parallaxBackgrounds: false,
     parallaxElements: true,

     // Hide parallax elements that move outside the viewport
     hideDistantElements: false,

     // Customise how elements are shown and hidden
     hideElement: function($elem) { $elem.hide(); },
     showElement: function($elem) { $elem.show(); }
    });


  },

  init: function() {

    let self = this;

    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };

    if( !isMobile.any() ){

      function checkInitialized() {

        // console.log('checking init');

        if($('.scroll-content').length) {
          clearInterval(initInterval);
          self.parallax();
          // console.log('parallax initialized');
        }

      }

      let initInterval = setInterval(checkInitialized, 1000);

      $(window).resize(function() {
          $('.scroll-content').stellar('refresh');
      });


    }


  }

}

export default Products;
