import helpers from '../helpers/helpers';
import overlay from './overlay';

const Work = {

  loadMore: function() {

    const trigger = $('.load-more a');
    const target = $('.projects');

    trigger.on('click', function(event) {

        event.preventDefault();

        if($(this).hasClass('loading')) {
          return;
        }

        $(this).addClass('loading');

        let data = {
          action: $(this).parent().data('action'),
          paged: $(this).parent().data('paged'),
          term: $(this).parent().data('term')
        };

        $.ajax({
          url: AjaxHandler.ajaxurl,
          type: 'GET',
          dataType: 'json',
          data: data
        })
        .done(function(response) {

          trigger.removeClass('loading');

          if(response.status === 'success') {

            trigger.parent().data('paged', parseInt(trigger.parent().data('paged') + 1));

            for( let i = 0; i < response.data.length; i++ ) {
              let project = $(response.data[i]);
              target.append(project);
            }

            helpers.triggerAnimationsInView(0);

            overlay.init();

          }

          else {
            trigger.parent().html('<div class="end"><a href="/contact">Contact us</a> to learn more about our work</div>');
          }


        })
        .fail(function() {
          console.log("error loading more work");
        });

    });

  },

  init: function() {
    this.loadMore();
  }

}

export default Work;
