const ProjectCarousel = {

  init: function() {

    const carousel = $('.project-gallery');

    let slidesToShow = 2;

    if(carousel.children().length <= 2) {
      slidesToShow = 1;
    }

    carousel.slick({
      slidesToShow: 2,
      centerMode: true,
      variableWidth:false,
      focusOnSelect: true,
      arrows:false,
      dots:true,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            centerMode: false,
            slidesToShow: 1,
            adaptiveHeight: true
          }
        }
      ]
    });

  }

}

export default ProjectCarousel;
