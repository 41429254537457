import helpers from '../helpers/helpers';

const mapStyle = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    }
]


const Contact = {

  init: function() {

    if(!$('.map').length) return;

    let markersList = [];
    let marker;
    let map;
    let office = $('.office');
    let sidebar = $('.sidebar');
    let animEnd = helpers.whichAnimationEvent();

    sidebar.one(animEnd, function(event) {

      $('.map-container').addClass('load');

      map = new google.maps.Map($('.map')[0], {
        zoom: 14,
        center: {
          lat: 45.4215,
          lng: -75.6972
        },
        mapTypeControl: false,
        streetViewControl: false,
        scrollwheel: false,
        styles: mapStyle
      });

      office.each(function(index) {

        let lat = $(this).data('lat');
        let lng = $(this).data('lng');

        let icon = new google.maps.MarkerImage(
            `/wp-content/themes/2ii/assets/build/img/contact/icon-${index}.png`,
            null, //size is determined at runtime
            null, //origin is 0,0
            null, //anchor is bottom center of the scaled image
            new google.maps.Size(35, 49)
        )

        marker = new google.maps.Marker({
          position: new google.maps.LatLng(lat, lng),
          map: map,
          icon: icon
        });

        markersList.push({
          lat: lat,
          lng: lng,
        });

        if(index === 0) {
          map.panTo(new google.maps.LatLng(lat, lng));
          sidebar.addClass(`colour-${index}`);
          $(this).addClass('active');
        }
      });

      office.on('click', function(event) {
        let index = office.index(this)
        let lat = markersList[index].lat;
        let lng = markersList[index].lng;
        map.panTo(new google.maps.LatLng(lat, lng));
        $('.office.active').removeClass('active');
        $(this).addClass('active');
        sidebar.removeClass('colour-1 colour-2 colour-2').addClass(`colour-${index}`);
      });

    });


  }

}

export default Contact;
