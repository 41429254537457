import isEmail from 'validator/lib/isEmail';
import swal from 'sweetalert';


const body = $('body');
const nav = $('.navigation-primary');

const Header = {

  nav: function() {

    let trigger = $('.toggle-nav');
    let self = this;

    trigger.on('click', function() {
    	body.toggleClass('nav-open').removeClass('mailing-list--visible');
      if(body.hasClass('nav-open')) {
        nav.attr('aria-hidden', false);
      }
      else {
        nav.attr('aria-hidden', true);
      }
      self.scrollNav();
    });


  },

  newsletter: function() {

    let trigger = $('.newsletter-toggle a');
    let close = $('.close-mailing-list');
    let form = $('.mailing-list');
    let self = this;

    trigger.add(close).on('click', function(event) {
      event.preventDefault();
    	body.toggleClass('mailing-list--visible').removeClass('nav-open');
      self.scrollNav();
    });

    form.on('submit', function(event) {
      event.preventDefault();

      let email = $('[type="email"]').val();

      if(!isEmail(email)) {
        swal({
          title: "Error!",
          text: "Please enter a valid email address",
          type: "error",
          confirmButtonColor: '#e5554f'
        });
        return;
      }

      $.ajax({
        url: AjaxHandler.ajaxurl,
        type: 'GET',
        dataType: 'JSON',
        data: {
          action: 'newsletter_signup',
          email: email
        },
      })
      .done(function(response) {
        if(response.response_type === 'success') {
          swal({
            title: "Success!",
            text: response.message,
            type: "success",
            confirmButtonColor: '#00afdb'
          });
        }
        else {
          console.log(response);
          swal({
            title: "Error!",
            text: "There was a problem subscribing you to the mailing list. Please ensure you have used a valid email address.",
            type: "error",
            confirmButtonColor: '#e5554f'
          });
        }
        console.log("success");
      })
      .fail(function() {
        console.log("error");
      })
      .always(function() {
        console.log("complete");
      });

    });

  },

  scrollNav: function() {

    let header = $('.website-header');

    header.animate({
      scrollTop: 0
    }, 300);

  },

  init: function() {
    this.nav();
    this.newsletter();
  }

}

export default Header;
